import $ from "jquery"
import "jquery-validation"

export function cmsFormControl() {
  function hideForm() {
    $("#submission-form").hide()
    $("#submitted-message").show()
  }

  function ajaxSubmitForm(form, event) {
    event.preventDefault()
    const this_form = $(form)
    const feedMessage = $('form[id="form_submission"').find("input[name='cms-feedback']").attr("value")
    $.ajax({
      method: this_form.attr("method"),
      data: new FormData(form),
      url: this_form.attr("action"),
      contentType: false,
      processData: false,
      dataType: "json",
      beforeSend() {
        $("#submission-spinner-overlay").css("display", "flex")
        $("input[type=submit]", this_form).attr("disabled", true).attr("value", "Submitting...")
      },
      success(responseJSON) {
        $("#cms-feedback").html(feedMessage)
        hideForm()
        swal.fire({
          type: "success",
          title: "Submitted!",
          text: feedMessage,
          confirmButtonText: "Ok"
        })
        window.location.href = responseJSON["redirect_to"];
      },
      error(error) {
        swal.fire({
          type: "error",
          title: "Error submitting form. Please try again later.",
          text: Object.entries(error.responseJSON).map(([key, value]) => (`${key}: ${value}`)),
          confirmButtonText: "Ok"
        })
      },
      complete() {
        $("#submission-spinner-overlay").hide()
        $("input[type=submit]", this_form).removeAttr("disabled").attr("value", "Submit Enquiry")
      }
    })
  }

  // eslint-disable-next-line no-unused-vars
  let validator

  // custom validation method "phoneNumber"
  $.validator.addMethod("phoneNumber", function (value, element) {
    // allow any non-whitespace characters as the host part
    return this.optional(element) || /^(?=[+\d])[\d \-+]{1,19}$/.test(value)
  }, "Please enter a valid phone number.")

  // custom validation method "cmsName"
  $.validator.addMethod("cmsName", function (value, element) {
    // allow any non-whitespace characters as the host part
    return this.optional(element) || /^[a-zA-z'-/ ]{0,20}$/.test(value)
  }, "Please enter letters, space, apostrophes and hyphens only.")

  // custom validation method "cmsName"
  $.validator.addMethod("auPostcode", function (value, element) {
    // allow any non-whitespace characters as the host part
    return this.optional(element) || /^[0-9]{0,5}$/.test(value)
  }, "Please enter maximum 5 numbers.")

  function bindValidation() {
    // reset the validator
    // try {
    //   validator.destroy()
    // } catch {
    //   // do nothing
    // }
    validator = $('form[id="form_submission"').validate({
      errorClass: "is-invalid",
      validClass: "is-valid",
      submitHandler(form, event) {
        event.preventDefault()
        // eslint-disable-next-line no-undef
        if (grecaptcha.getResponse().length === 0) {
          swal.fire({
            type: "info",
            title: "Please verify that you're not a robot",
            confirmButtonText: "Ok"
          })
        } else {
          ajaxSubmitForm(form, event)
        }
      }
    })
  }

  bindValidation()

  // $("input[type='radio']").on("change", e => {
  //   const $element = $(e.currentTarget)
  //   if ($element.attr("cms-required-target")) {
  //     const $inputGroup = $(`input[name='${$element.attr("name")}']`)
  //     $inputGroup.each((index, inputElement) => {
  //       const $inputElement = $(inputElement)
  //       const $targetElement = $($inputElement.attr("cms-required-target"))
  //       const $inputSelected = $(`input#${$inputElement.attr("id")}:checked`).val()
  //       if ($inputSelected) {
  //         $targetElement.attr("required", true)
  //         $targetElement.attr("placeholder", $targetElement.attr("placeholder-required"))
  //       } else {
  //         $targetElement.attr("required", false)
  //         $targetElement.attr("placeholder", $targetElement.attr("placeholder-optional"))
  //       }
  //     })
  //     bindValidation()
  //   }
  // })
}


export function cmsFormFileDropable() {
  const $fileBox = $(".file_upload_box")
  const $input = $fileBox.find("input[type='file']").first()
  const $label = $(`label[for='${$input.attr("id")}']`).first()

  if ($fileBox.length) {
    const supportDrop = (function () {
      const div = document.createElement("div")
      return (("draggable" in div) || ("ondragstart" in div && "ondrop" in div))
    }())
    const showFiles = function (file) {
      $label.find(".message").text(file.name)
    }
    if (supportDrop) {
      $fileBox.addClass("support-drop")

      $fileBox.on("drag dragstart dragend dragover dragenter dragleave drop", e => {
        e.preventDefault()
        e.stopPropagation()
      })
        .on("dragover dragenter", () => {
          $fileBox.addClass("is-dragover")
        })
        .on("dragleave dragend drop", () => {
          $fileBox.removeClass("is-dragover")
        })
        .on("drop", e => {
          const fileCount = e.originalEvent.dataTransfer.files.length
          const validFile = e.originalEvent.dataTransfer.files[fileCount - 1]
          $input[0].files = e.originalEvent.dataTransfer.files
          showFiles(validFile)
        })
    }
    $input.on("change", e => {
      showFiles(e.target.files[0])
    })
  }
}