import Util from "./Util/configurator-util"
import Log from "./Util/configurator-log"
import Series from "./Types/configurator-series"
import Design from "./Types/configurator-design"
import Facade from "./Types/configurator-facade"
import Section from "./Types/configurator-section"
import OptionGroup from "./Types/configurator-optiongroup"
import ConfigOption from "./Types/configurator-option"
import Roof from "./Types/configurator-roof"
import GroupItem from "./Types/configurator-groupitem"


const STEPS = {
  DESIGN: 0,
  FACADE: 1,
  SITE_COSTS: 2,
  INCLUSIONS: 3,
  CONFIGURATION: 4,
  CONTACT: 5,
  FINAL: 6
}

const ACTIONS = {
  SET_STEP_BACK: "setStepBack",
  SET_STEP_NEXT: "setStepNext",
  SET_NAME_FILTER: "setNameFilter",
  SET_SERIES_FILTERS: "setSeriesFilters",
  SET_BEDROOM_FILTERS: "setBedroomFilters",
  SET_BATHROOM_FILTERS: "setBathroomFilters",
  SET_GARAGE_FILTERS: "setGarageFilters",
  SET_PRICE_FILTERS: "setPriceFilters",
  SET_DISPLAY_BY: "setDisplayBy",
  SET_PRICE_LEVEL: "setPriceLevel",
  INITIALISE_APP: "initApp",
  INITIALISE_SECTIONS: "initSections",
  SELECT_DESIGN: "selectDesign",
  SELECT_FACADE: "selectFacade",
  SELECT_ROOF: "selectRoofType",
  SELECT_OPTION: "selectOption",
  SUBMIT_CONFIGURATION: "submitConfiguration",
  SORT_DESIGNS: "sortDesigns",
  SORT_FACADES: "sortFacades"
}

function ConfiguratorModel() {
  this.seriesList = []
  this.designMap = {}
  this.sectionLists = {}
  this.roofTypes = []
  this.standardInclusions = []
  this.standardSiteCosts = []

  this.selectedPriceLevel = "1"
  this.selectedDesign = ""
  this.selectedFacade = ""
  this.selectedRoof = ""

  this.seriesFilters = []
  this.bedroomFilters = [1, 6]
  this.bathroomFilters = [1, 4]
  this.garageFilters = [0, 3]
  this.priceFilters = ["any", "any"]
  this.nameFilter = ""

  this.assignedIDs = []
  this.formValues = {}

  this.endpointResponse = window.location.hostname === "beechwoodhomes.com.au" ? {
    get: ConfiguratorModel.endpoints.productionGet,
    post: ConfiguratorModel.endpoints.productionPost
  } : null
}

/**
 * Get a specific series
 * @param {String} seriesID
 * @returns {Series}
 */
ConfiguratorModel.prototype.getSeries = function (seriesID) {
  let toReturn = null

  Util.each(this.seriesList, series => {
    if (series.getInternalID() === seriesID) toReturn = series
  })

  return toReturn
}
/**
 * Add a series
 * @param {Series} toAdd
 */
ConfiguratorModel.prototype.addSeries = function (toAdd) {
  if (!this.getSeries(toAdd.getInternalID())) this.seriesList.push(toAdd)
}
/**
 * Get the entire series list
 * @returns {Series[]}
 */
ConfiguratorModel.prototype.getSeriesList = function () { return this.seriesList.slice() }
/**
 * Get a specific design
 * @param {String} designID
 * @returns {Design}
 */
ConfiguratorModel.prototype.getDesign = function (designID) { return this.designMap[designID] || null }
/**
* Add a design
* @param {Design} toAdd
*/
ConfiguratorModel.prototype.addDesign = function (toAdd) {
  if (!this.getDesign(toAdd.getInternalID())) this.designMap[toAdd.getInternalID()] = toAdd
}
/**
 * Get the entire design list
 * @returns {Design[]}
 */
ConfiguratorModel.prototype.getDesignList = function () { return Object.values(this.designMap) }
/**
 * Internal use only. Generates a key to access the this.sectionLists map for a specific design/facade combination
 * @param {String} design
 * @param {String} facade
 * @returns {String}
 */
ConfiguratorModel.generateSectionKey = function (design, facade) {
  return ["d", design, "f", facade].join("")
}
/**
 * Get a specific section
 * @param {String} sectionName
 * @returns {Section}
 */
ConfiguratorModel.prototype.getSection = function (sectionName) {
  let toReturn = null

  if (!this.selectedDesign || !this.selectedFacade) return toReturn

  const key = ConfiguratorModel.generateSectionKey(this.selectedDesign, this.selectedFacade)

  Util.each(this.sectionLists[key], section => {
    if (section.getName() === sectionName) toReturn = section
  })

  return toReturn
}
/**
 * Add a section
 * @param {Section} toAdd
 */
ConfiguratorModel.prototype.addSection = function (toAdd) {
  let key

  if (!this.selectedDesign || !this.selectedFacade) return

  if (!this.getSection(toAdd.getName())) {
    key = ConfiguratorModel.generateSectionKey(this.selectedDesign, this.selectedFacade)
    if (!this.sectionLists[key]) this.sectionLists[key] = []
    this.sectionLists[key].push(toAdd)
  }
}
/**
 * Get the entire section list
 * @returns {Section[]}
 */
ConfiguratorModel.prototype.getSectionList = function () {
  if (!this.selectedDesign || !this.selectedFacade) return []

  const key = ConfiguratorModel.generateSectionKey(this.selectedDesign, this.selectedFacade)

  if (!this.sectionLists[key]) return []
  return this.sectionLists[key].slice().sort((a, b) => a.getSort() - b.getSort())
}

/**
 * Get all roofs compatible with current series/design/facade selections
 * @returns {Roof[]}
 */
ConfiguratorModel.prototype.getRoofs = function () {
  const toReturn = []

  Util.each(this.roofTypes, roofType => {
    if (this.getSelectedSeries() && roofType.getInvalidSeries().indexOf(this.getSelectedSeries()) >= 0) return
    if (this.getSelectedDesign() && roofType.getInvalidDesigns().indexOf(this.getSelectedDesign()) >= 0) return
    if (this.getSelectedFacade() && roofType.getInvalidFacades().indexOf(this.getSelectedFacade()) >= 0) return

    toReturn.push(roofType)
  })

  return toReturn
}

/**
 *
 * @param {String} roofID
 * @returns {Roof}
 */
ConfiguratorModel.prototype.getRoof = function (roofID) {
  let toReturn = null

  Util.each(this.roofTypes, roofType => {
    if (roofType.getInternalID() === roofID) toReturn = roofType
  })

  return toReturn
}

/**
 * Get all standard inclusions compatible with current series/design/facade selections
 * @returns {GroupMember[]}
 */
ConfiguratorModel.prototype.getStandardInclusions = function () {
  const toReturn = []

  Util.each(this.standardInclusions, inclusionItem => {
    if (this.getSelectedSeries() && inclusionItem.getInvalidSeries().indexOf(this.getSelectedSeries()) >= 0) return
    if (this.getSelectedDesign() && inclusionItem.getInvalidDesigns().indexOf(this.getSelectedDesign()) >= 0) return
    if (this.getSelectedFacade() && inclusionItem.getInvalidFacades().indexOf(this.getSelectedFacade()) >= 0) return

    toReturn.push(...inclusionItem.getMemberItems(this.getSelectedSeries(), this.getSelectedDesign(), this.getSelectedFacade()))
  })

  return toReturn
}

/**
 * Get all standard site costs compatible with current series/design/facade selections
 * @returns {GroupMember[]}
 */
ConfiguratorModel.prototype.getStandardSiteCosts = function () {
  const toReturn = []

  Util.each(this.standardSiteCosts, inclusionItem => {
    if (this.getSelectedSeries() && inclusionItem.getInvalidSeries().indexOf(this.getSelectedSeries()) >= 0) return
    if (this.getSelectedDesign() && inclusionItem.getInvalidDesigns().indexOf(this.getSelectedDesign()) >= 0) return
    if (this.getSelectedFacade() && inclusionItem.getInvalidFacades().indexOf(this.getSelectedFacade()) >= 0) return

    toReturn.push(...inclusionItem.getMemberItems(this.getSelectedSeries(), this.getSelectedDesign(), this.getSelectedFacade()))
  })

  return toReturn
}

/**
 * Get the selected price level
 * @returns {String}
 */
ConfiguratorModel.prototype.getSelectedPriceLevel = function () { return String(this.selectedPriceLevel) }
/**
 * Set the selected price level
 */
ConfiguratorModel.prototype.setSelectedPriceLevel = function (priceLevel) { this.selectedPriceLevel = priceLevel }
/**
 * Get the selected series (calculated based on selected design)
 * @returns {String}
 */
ConfiguratorModel.prototype.getSelectedSeries = function () {
  let toReturn = ""
  if (!this.getSelectedDesign()) return toReturn

  Util.each(this.getSeriesList(), series => {
    Util.each(series.getDesignList(), design => {
      if (design.getInternalID() === this.getSelectedDesign()) toReturn = series.getInternalID()
    })
  })

  return String(toReturn)
}
/**
 * Get the selected design
 * @returns {String}
 */
ConfiguratorModel.prototype.getSelectedDesign = function () { return String(this.selectedDesign) }
/**
 * Set the selected design
 * @param {String}
 */
ConfiguratorModel.prototype.setSelectedDesign = function (design) { this.selectedDesign = design }
/**
 * Get the selected facade
 * @returns {String}
 */
ConfiguratorModel.prototype.getSelectedFacade = function () { return String(this.selectedFacade) }
/**
 * Set the selected facade
 * @param {String}
 */
ConfiguratorModel.prototype.setSelectedFacade = function (facade) { this.selectedFacade = facade }
/**
 * Get the selected roof type
 * @returns {String}
 */
ConfiguratorModel.prototype.getSelectedRoof = function () { return String(this.selectedRoof) }
/**
 * Set the selected roof type
 * @param {String} roofType
 */
ConfiguratorModel.prototype.setSelectedRoof = function (roofType) { this.selectedRoof = roofType }
/**
 * Get current series filters
 * @returns {String[]}
 */
ConfiguratorModel.prototype.getSeriesFilters = function () { return this.seriesFilters.slice().sort((a, b) => Number(a) - Number(b)) }
/**
 * Set current series filters
 * @param {String[]} filters
 */
ConfiguratorModel.prototype.setSeriesFilters = function (filters) { this.seriesFilters = filters || [] }
/**
 * Get current bedroom filters
 * @returns {Number[]}
 */
ConfiguratorModel.prototype.getBedroomFilters = function () { return this.bedroomFilters.slice(0, 2) }
/**
 * Set current bedroom filters
 * @param {Number[]} filters
 */
ConfiguratorModel.prototype.setBedroomFilters = function (filters) { this.bedroomFilters = filters || [1, 6] }
/**
 * Get current bathroom filters
 * @returns {Number[]}
 */
ConfiguratorModel.prototype.getBathroomFilters = function () { return this.bathroomFilters.slice(0, 2) }
/**
 * Set current bathroom filters
 * @param {Number[]} filters
 */
ConfiguratorModel.prototype.setBathroomFilters = function (filters) { this.bathroomFilters = filters || [1, 4] }
/**
 * Get current garage filters
 * @returns {Number[]}
 */
ConfiguratorModel.prototype.getGarageFilters = function () { return this.garageFilters.slice(0, 2) }
/**
 * Set current garage filters
 * @param {Number[]} filters
 */
ConfiguratorModel.prototype.setGarageFilters = function (filters) { this.garageFilters = filters || [0, 3] }
/**
 * Get current price filters
 * @returns {Number[]}
 */
ConfiguratorModel.prototype.getPriceFilters = function () { return this.priceFilters.slice(0, 2) }
/**
 * Set current price filters
 * @param {Number[]} filters
 */
ConfiguratorModel.prototype.setPriceFilters = function (filters) { this.priceFilters = filters || ["any", "any"] }
/**
 * Get current name filter
 * @returns {String}
 */
ConfiguratorModel.prototype.getNameFilter = function () { return String(this.nameFilter) }
/**
 * Set current name filter
 * @param {String} filter
 */
ConfiguratorModel.prototype.setNameFilter = function (filter) { this.nameFilter = filter || "" }

/**
 * Internal Use Only
 */
ConfiguratorModel.endpoints = {
  // sandboxGet: "https://1248291-sb1.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=736&deploy=1&compid=1248291_SB1&h=e8a1f68a7712c43d5d60",
  sandboxGet: "https://1248291.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=867&deploy=1&compid=1248291&ns-at=AAEJ7tMQi3T3kW50zx1qLjB7AKrGTm48JwXSAGKXdfTi7ww6rhc",
  sandboxPost: "/form_submissions/configurator",
  // productionGet: "https://1248291.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=867&deploy=1&compid=1248291&h=99667d8b8a0096a21736",
  productionGet: "https://1248291.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=867&deploy=1&compid=1248291&ns-at=AAEJ7tMQi3T3kW50zx1qLjB7AKrGTm48JwXSAGKXdfTi7ww6rhc",
  productionPost: "/form_submissions/configurator"
}
// productionGet: "https://1248291.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=867&deploy=1&compid=1248291&h=99667d8b8a0096a21736",

ConfiguratorModel.prototype.getEndpointSelection = function () {
  if (this.endpointResponse) {
    return this.endpointResponse
  }

  let userResponse = prompt('Which domain do you want to connect to? Use P for Production or S for Sandbox', 'S')
  if (!userResponse) userResponse = 'S'

  this.endpointResponse = {
    get: userResponse.toUpperCase().charAt(0) === 'P' ? ConfiguratorModel.endpoints.productionGet : ConfiguratorModel.endpoints.sandboxGet,
    post: userResponse.toUpperCase().charAt(0) === 'P' ? ConfiguratorModel.endpoints.productionPost : ConfiguratorModel.endpoints.sandboxPost
  }
  return this.endpointResponse
}

ConfiguratorModel.prototype.getEndpoint = function (method) {
  switch (method) {
    case "GET":
      switch (window.location.hostname) {
        case "localhost":
        case "staging.beechwoodhomes.com.au":
        case "sentia.ngrok.io":
        case 'saasinabox.com':
          console.log({
            hostname: window.location.hostname,
            pathname: window.location.pathname,
            endpoint: this.getEndpointSelection().get
          })
          return this.getEndpointSelection().get

        default:
          if (/^192\.168\.0\.\d{1,3}/.test(window.location.hostname)) {
            console.log({
              hostname: window.location.hostname,
              pathname: window.location.pathname,
              endpoint: this.getEndpointSelection().get
            })
            return this.getEndpointSelection().get
          }

          console.log({
            hostname: window.location.hostname,
            pathname: window.location.pathname,
            endpoint: ConfiguratorModel.endpoints.productionGet
          })
          return ConfiguratorModel.endpoints.productionGet
      }

    case "POST":
      switch (window.location.hostname) {
        case "localhost":
        case "staging.beechwoodhomes.com.au":
        case "sentia.ngrok.io":
        case 'saasinabox.com':
          console.log({
            hostname: window.location.hostname,
            pathname: window.location.pathname,
            endpoint: this.getEndpointSelection().post
          })
          return this.getEndpointSelection().post

        default:
          if (/^192\.168\.0\.\d{1,3}/.test(window.location.hostname)) {
            console.log({
              hostname: window.location.hostname,
              pathname: window.location.pathname,
              endpoint: this.getEndpointSelection().post
            })
            return this.getEndpointSelection().post
          }

          console.log({
            hostname: window.location.hostname,
            pathname: window.location.pathname,
            endpoint: ConfiguratorModel.endpoints.productionPost
          })
          return ConfiguratorModel.endpoints.productionPost
      }

    default:
      return this.getEndpoint("GET")
  }
}

/**
 * Initialise data on page load (series/designs/facades)
 * @param {Object} data
 * @returns {Object} An object with data required for state update
 * @throws {Error} If the call to fetch failed (i.e. no connection)
 * @throws {Error} If the fetch resulted in a non-200 HTTP status
 * @throws {Error} If an error on the server meant that the data could not be retrieved
 */
ConfiguratorModel.prototype.initialiseData = async function (abortController, dispatch) {
  const payload = { method: "getInitialisationData" }

  try {
    const response = await fetch(this.getEndpoint("GET"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload),
      signal: abortController.signal
    })

    if (!response.ok) { // non-200 HTTP code returned
      dispatch({
        type: ACTIONS.INITIALISE_APP,
        payload: {
          designs: {
            loading: false,
            designs: [],
            error: "Oops! Something went wrong, please reload the page to try again."
          },
          roofs: [],
          standardInclusions: [],
          standardSiteCosts: []
        }
      })
      return
    }

    const data = await response.json()

    if (!data.success) {
      dispatch({
        type: ACTIONS.INITIALISE_APP,
        payload: {
          designs: {
            loading: false,
            designs: [],
            error: "An internal server error was encountered. Please try again later."
          },
          roofs: [],
          standardInclusions: [],
          standardSiteCosts: []
        }
      })
      return
    }

    // set up key/value maps for all returned series, designs and facades
    const facadeMap = {}
    Util.each(data.result.facades, facadeObject => {
      if (!facadeObject.item) return
      facadeMap[facadeObject.internalID] = new Facade(facadeObject)
    })

    const designMap = {}
    Util.each(data.result.designs, designObject => {
      if (!designObject.item) return
      designMap[designObject.internalID] = new Design(designObject)
    })

    const seriesMap = {}
    Util.each(data.result.series, seriesObject => {
      seriesMap[seriesObject.internalID] = new Series(seriesObject)
    })

    // for each entry in the design/facade map returned, add the available facades to the relevant design
    Util.each(data.result.designFacadeMap, (facadeIDList, designID) => {
      const design = designMap[designID]
      if (!design) return

      Util.each(facadeIDList, facadeID => {
        if (facadeMap[facadeID]) design.addFacade(facadeMap[facadeID])
      })
    })

    // inspect each design to ensure at least one facade is defined, and discard if none found
    Util.each(designMap, design => {
      if (!design.getFacadeList().length) designMap[design.getInternalID()] = null
    })

    // for each entry in the series/desing map returned, add the available designs to the relevant series
    Util.each(data.result.seriesDesignMap, (designIDList, seriesID) => {
      const series = seriesMap[seriesID]
      if (!series) return

      Util.each(designIDList, designID => {
        if (designMap[designID]) series.addDesign(designMap[designID])
      })
    })

    // inspect each series to ensure at least one design is defined, and discard if none found
    Util.each(seriesMap, series => {
      if (!series.getDesignList().length) seriesMap[series.getInternalID()] = null
    })

    // populate this.seriesList and this.designMap with remaining records
    Util.each(seriesMap, series => {
      if (series) this.addSeries(series)
    })

    Util.each(designMap, design => {
      if (design) this.addDesign(design)
    })

    // store returned roofs
    Util.each(data.result.roofs, roof => {
      this.roofTypes.push(new Roof(roof))
    })

    // store returned standard inclusions
    Util.each(data.result.standardInclusions, standardInclusionItem => {
      this.standardInclusions.push(new GroupItem(standardInclusionItem))
    })

    // store returned sitecosts
    Util.each(data.result.siteCosts, siteCostItem => {
      this.standardSiteCosts.push(new GroupItem(siteCostItem))
    })

    // testing preload images
    window.configurator.images = window.configurator.images || {}

    Util.each(this.getDesignList(), design => {
      this.storeImage("groundRHG", design.getInternalID(), design.getGroundFloorPlanRHG())
      this.storeImage("groundLHG", design.getInternalID(), design.getGroundFloorPlanLHG())
      this.storeImage("firstRHG", design.getInternalID(), design.getFirstFloorPlanRHG())
      this.storeImage("firstLHG", design.getInternalID(), design.getFirstFloorPlanLHG())

      Util.each(design.getFacadeImages(), facadeImageObject => {
        this.storeImage("facade", facadeImageObject.facadeID, facadeImageObject.url)
      })
    })

    dispatch({
      type: ACTIONS.INITIALISE_APP,
      payload: {
        designs: {
          loading: false,
          designs: this.getFilteredDesigns(),
          error: ""
        },
        roofs: this.getRoofs(),
        standardInclusions: this.getStandardInclusions(),
        standardSiteCosts: this.getStandardSiteCosts()
      }
    })
  } catch (e) {
    dispatch({
      type: ACTIONS.INITIALISE_APP,
      payload: {
        designs: {
          loading: false,
          designs: [],
          error: "Oops! It looks like you're having trouble connecting to the server. Please check your connection and try again."
        },
        roofs: [],
        standardInclusions: [],
        standardSiteCosts: []
      }
    })
  }
}

ConfiguratorModel.prototype.storeImage = function (type, id, url) {
  // window.configurator.images = window.configurator.images || {}
  if (!type || !id || !url) return
  const key = [type, id].join("")
  if (window.configurator.images[key]) return
  window.configurator.images[key] = new Image()
  window.configurator.images[key].src = url
}

/**
 * Initialise sections for a selected design/facade
 * @param {Object} data
 * @returns {Object} An object with data required for state update
 */
ConfiguratorModel.prototype.initialiseSections = async function (abortController, dispatch) {
  dispatch({
    type: ACTIONS.INITIALISE_SECTIONS,
    payload: {
      sections: {
        loading: true,
        sections: [],
        error: ""
      }
    }
  })
  const selectedDesign = this.getSelectedDesign()
  const selectedFacade = this.getSelectedFacade()

  if (!selectedDesign || !selectedFacade || this.getSectionList().length) {
    // there is no currently selected design or facade, or the list of sections has already been initialised for the selected design/facade
    dispatch({
      type: ACTIONS.INITIALISE_SECTIONS,
      payload: {
        sections: {
          loading: false,
          sections: this.getSectionList(),
          error: ""
        }
      }
    })
    return
  }

  const selectedSeries = this.getSelectedSeries()

  const generateCandidateID = () => String(Math.floor(Math.random() * 100000))
  const generateAssignedID = () => {
    let candidate = generateCandidateID()
    while (this.assignedIDs.indexOf(candidate) >= 0) {
      candidate = generateCandidateID()
    }

    this.assignedIDs.push(candidate)
    return candidate
  }

  const items = [
    this.getDesign(this.getSelectedDesign()).getItem().getInternalID(),
    this.getDesign(this.getSelectedDesign()).getFacade(this.getSelectedFacade()).getItem().getInternalID()
  ]

  const payload = { method: "getConfigOptions", data: { forItems: items } }

  try {
    const response = await fetch(this.getEndpoint("GET"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload),
      signal: abortController.signal
    })

    if (!response.ok) { // non-200 HTTP code returned
      dispatch({
        type: ACTIONS.INITIALISE_SECTIONS,
        payload: {
          sections: {
            loading: false,
            sections: [],
            error: "Oops! Something went wrong, please reload the page to try again."
          }
        }
      })
      return
    }

    const data = await response.json()

    if (!data.success) {
      dispatch({
        type: ACTIONS.INITIALISE_SECTIONS,
        payload: {
          sections: {
            loading: false,
            sections: [],
            error: "An internal server error was encountered. Please try again later."
          }
        }
      })
      return
    }

    Util.each(data.result, sectionGroup => {
      let sectionAdded = this.getSection(sectionGroup.sectionName)
      let groupAdded

      if (sectionAdded) {
        groupAdded = sectionAdded.getGroup(sectionGroup.groupName)
      }

      Util.each(sectionGroup.options, optionObject => {
        if (selectedSeries && optionObject.invalidSeries.indexOf(selectedSeries) >= 0) return
        if (optionObject.invalidDesigns.indexOf(selectedDesign) >= 0) return
        if (optionObject.invalidFacades.indexOf(selectedFacade) >= 0) return

        if (!sectionAdded) {
          sectionAdded = new Section({
            name: sectionGroup.sectionName,
            sort: sectionGroup.sectionSort
          })
          this.addSection(sectionAdded)
        }

        if (!groupAdded) {
          groupAdded = new OptionGroup({
            name: sectionGroup.groupName,
            sort: sectionGroup.groupSort
          })
          sectionAdded.addGroup(groupAdded)
          sectionAdded.groups.sort((a, b) => a.getSort() - b.getSort())
        }

        groupAdded.addOption(new ConfigOption({
          ...optionObject,
          assignedID: generateAssignedID(),
          selected: optionObject.isDefault
        }))
      })
    })

    dispatch({
      type: ACTIONS.INITIALISE_SECTIONS,
      payload: {
        sections: {
          loading: false,
          sections: this.getSectionList(),
          error: ""
        }
      }
    })
  } catch (e) {
    dispatch({
      type: ACTIONS.INITIALISE_SECTIONS,
      payload: {
        sections: {
          loading: false,
          sections: [],
          error: "Oops! It looks like you're having trouble connecting to the server. Please check your connection and try again."
        }
      }
    })
  }
}

/**
 * Get the list of designs, filtered according to current filter settings
 * @returns {Design[]}
 */
ConfiguratorModel.prototype.getFilteredDesigns = function () {
  const toReturn = []

  Util.each(this.getSeriesList(), series => {
    if (this.getSeriesFilters().length === 0 || this.getSeriesFilters().indexOf(series.getInternalID()) >= 0) toReturn.push(...series.getDesignList())
  })

  return toReturn.filter(design => !ConfiguratorModel.isFilteredDesign(design,
    this.getBedroomFilters(),
    this.getBathroomFilters(),
    this.getGarageFilters(),
    this.getPriceFilters(),
    this.getNameFilter(),
    this.getSelectedPriceLevel())).sort((a, b) => a.getName().localeCompare(b.getName()))
}

ConfiguratorModel.isFilteredDesign = function (design, bedroomFilters, bathroomFilters, garageFilters, priceFilters, nameFilter, priceLevel) {
  if (design.getBeds() < bedroomFilters[0]) {
    Log.audit({
      title: "Design excluded due to Bedroom Lower Bound filtering",
      details: {
        lowerBound: bedroomFilters[0],
        designBeds: design.getBeds(),
        designName: design.getName()
      }
    })
    return true
  }

  if (design.getBeds() > bedroomFilters[1]) {
    Log.audit({
      title: "Design excluded due to Bedroom Upper Bound filtering",
      details: {
        upperBound: bedroomFilters[1],
        designBeds: design.getBeds(),
        designName: design.getName()
      }
    })
    return true
  }

  if (design.getBaths() < bathroomFilters[0]) {
    Log.audit({
      title: "Design excluded due to Bathroom Lower Bound filtering",
      details: {
        lowerBound: bathroomFilters[0],
        designBaths: design.getBaths(),
        designName: design.getName()
      }
    })
    return true
  }

  if (design.getBaths() > bathroomFilters[1]) {
    Log.audit({
      title: "Design excluded due to Bathroom Upper Bound filtering",
      details: {
        upperBound: bathroomFilters[1],
        designBaths: design.getBaths(),
        designName: design.getName()
      }
    })
    return true
  }

  if (design.getGarages() < garageFilters[0]) {
    Log.audit({
      title: "Design excluded due to Garage Lower Bound filtering",
      details: {
        lowerBound: garageFilters[0],
        designGarages: design.getGarages(),
        designName: design.getName()
      }
    })
    return true
  }

  if (design.getGarages() > garageFilters[1]) {
    Log.audit({
      title: "Design excluded due to Garages Upper Bound filtering",
      details: {
        upperBound: garageFilters[1],
        designGarages: design.getGarages(),
        designName: design.getName()
      }
    })
    return true
  }

  if (!design.getItem()) {
    Log.audit({
      title: "Design excluded due to Missing Item on Design",
      details: {
        designName: design.getName()
      }
    })
    return true
  }

  if (design.getItem().getPriceLevels().indexOf(priceLevel) < 0) {
    Log.audit({
      title: "Design excluded due to Item on Design missing selected Price Level",
      details: {
        priceLevel,
        designPriceLevels: design.getItem().getPriceLevels(),
        designName: design.getName()
      }
    })
    return true
  }

  if (Number(design.getItem().getPriceWithTax(priceLevel)) < priceFilters[0]) {
    Log.audit({
      title: "Design excluded due to Price Lower Bound filtering",
      details: {
        lowerBound: priceFilters[0],
        price: Number(design.getItem().getPriceWithTax(priceLevel)),
        designName: design.getName()
      }
    })
    return true
  }

  if (Number(design.getItem().getPriceWithTax(priceLevel)) > priceFilters[1]) {
    Log.audit({
      title: "Design excluded due to Price Upper Bound filtering",
      details: {
        upperBound: priceFilters[1],
        price: Number(design.getItem().getPriceWithTax(priceLevel)),
        designName: design.getName()
      }
    })
    return true
  }

  if (nameFilter && design.getName().toUpperCase().indexOf(nameFilter.toUpperCase()) !== 0) {
    Log.audit({
      title: "Design excluded due to Name filtering",
      details: {
        nameFilter,
        designName: design.getName()
      }
    })
    return true
  }

  return false
}

/**
 * Get the list of facades for the currently selected design
 * @returns {Facade[]}
 */
ConfiguratorModel.prototype.getFilteredFacades = function () {
  const toReturn = []

  if (!this.getSelectedDesign()) return toReturn

  return this.getDesign(this.getSelectedDesign()).getFacadeList()
}

/**
 * Make the calculations and ajustments to selected options after a user checks or unchecks an option
 * @param {String} assignedID
 * @param {Boolean} checked
 */
ConfiguratorModel.prototype.calculateSelections = function (assignedID, checked) {
  let targetOptionItem

  // if the option was selected, and it is an invalid item for a different, currently selected option, deselect the other option
  if (checked) {
    Util.each(this.getSectionList(), section => {
      Util.each(section.getGroups(), group => {
        const targetOption = group.getOptions().filter(option => option.getAssignedID() === assignedID)

        if (targetOption.length) targetOptionItem = targetOption[0].getItemInternalID()
      })
    })

    Util.each(this.getSectionList(), section => {
      Util.each(section.getGroups(), group => {
        const groupHasTargetOption = group.getOptions().filter(option => option.getAssignedID() === assignedID).length
        const selectedOption = group.getOptions().filter(option => option.isSelected())

        if (groupHasTargetOption || !selectedOption.length) return

        const selectedOptionInvalidItems = selectedOption[0].getIncompatibleItems()
        const selectedOptionHasTargetSetInvalid = selectedOptionInvalidItems.filter(itemID => targetOptionItem === itemID).length

        if (selectedOptionHasTargetSetInvalid) selectedOption[0].isSelected(false)
      })
    })
  }

  // find the group containing the user-selected (or deselected) option, and update each option in the group to match the user action
  Util.each(this.getSectionList(), section => {
    Util.each(section.getGroups(), group => {
      const groupHasTargetOption = group.getOptions().filter(option => option.getAssignedID() === assignedID).length

      if (groupHasTargetOption) {
        Util.each(group.getOptions(), option => {
          if (checked) {
            // user just checked the option - make option selected and deselect other options
            option.isSelected(option.getAssignedID() === assignedID)
          } else {
            // user just unchecked the option - select default option and deselect other options
            // option.isSelected(option.isDefaultOption())

            // user just unchecked the option - deselect all options
            option.isSelected(false)
          }
        })
      }
    })
  })
  const running = true
  while (running) {
    const invalidItems = []
    let changed = false

    // get a list of invalid options for all selections
    Util.each(this.getSectionList(), section => {
      Util.each(section.getGroups(), group => {
        Util.each(group.getOptions(), option => {
          if (option.isSelected()) {
            invalidItems.push(...option.getIncompatibleItems())
          }
        })
      })
    })

    // re-examine each group. If the group contains a selected option that is found in the invalid items array, deselect that option. If the default option item is also not in the invalid items array, select that, otherwise select the cheapest option with an item not in the invalid items array. Set changed to true so that the list of invalid items is repopulated and all selections are re-examined recursively
    Util.each(this.getSectionList(), section => {
      Util.each(section.getGroups(), group => {
        const selectedOption = group.getOptions().filter(option => option.isSelected())

        if (selectedOption.length) {
          // there is an option currently selected
          const selectedOptionItem = selectedOption[0].getItemInternalID()
          const invalidItemFound = invalidItems.filter(itemID => selectedOptionItem === itemID).length

          if (invalidItemFound) {
            selectedOption[0].isSelected(false)
            changed = true
          }
        } else {
          // there is no currently selected option. Examine each option in order of default first, then zero-priced options, then cheapest to most expensive, try to find an option that is not incompatible, and select it
          let foundCompatibleOption = false
          Util.each(group.getOptions(), option => {
            if (foundCompatibleOption) return

            const currentOptionItem = option.getItemInternalID()
            const invalidItemFound = invalidItems.filter(itemID => currentOptionItem === itemID).length

            if (!invalidItemFound) {
              option.isSelected(true)
              foundCompatibleOption = true
              changed = true
            }
          })

          /* const defaultOption = group.getOptions().filter(option => option.isDefaultOption())[0]
          const defaultOptionItem = defaultOption.getItemInternalID()
          const invalidItemFound = invalidItems.filter(itemID => defaultOptionItem === itemID).length

          if (!invalidItemFound) {
            defaultOption.isSelected(true)
            changed = true
          } */
        }
      })
    })

    if (!changed) break
  }
}

/**
 * Get the total price of all selections
 * @returns {Number}
 */
ConfiguratorModel.prototype.getTotal = function (step) {
  let toReturn = 0

  if (this.getSelectedPriceLevel()) {
    if (step >= STEPS.DESIGN && this.getSelectedDesign()) {
      toReturn += this.getSelectedDesignTotal()
    }

    if (step >= STEPS.FACADE && this.getSelectedDesign() && this.getSelectedFacade()) {
      toReturn += this.getSelectedFacadeTotal()
    }

    if (step >= STEPS.FACADE && this.getSelectedRoof()) {
      toReturn += this.getSelectedRoofTotal()
    }

    if (step >= STEPS.SITE_COSTS) {
      toReturn += this.getStandardSiteCostsTotal()
    }

    if (step >= STEPS.INCLUSIONS) {
      toReturn += this.getStandardInclusionsTotal()
    }

    if (step >= STEPS.CONFIGURATION) {
      toReturn += this.getConfigOptionsTotal()
    }
  }

  return toReturn
}

ConfiguratorModel.prototype.getSelectedDesignTotal = function () {
  return Number(this.getDesign(this.getSelectedDesign()).getItem().getPriceWithTax(this.getSelectedPriceLevel()))
}

ConfiguratorModel.prototype.getSelectedRoofTotal = function () {
  return Number(this.getRoofs().filter(roof => roof.getInternalID() === this.getSelectedRoof())[0].getPriceWithTax(this.getSelectedPriceLevel()))
}

ConfiguratorModel.prototype.getSelectedFacadeTotal = function () {
  return Number(this.getDesign(this.getSelectedDesign()).getFacade(this.getSelectedFacade()).getItem().getPriceWithTax(this.getSelectedPriceLevel()))
}

ConfiguratorModel.prototype.getStandardSiteCostsTotal = function () {
  let toReturn = 0
  Util.each(this.getStandardSiteCosts(), groupMember => {
    toReturn += Number(groupMember.getTotalPriceWithTax(this.getSelectedPriceLevel()))
  })
  return toReturn
}

ConfiguratorModel.prototype.getStandardInclusionsTotal = function () {
  let toReturn = 0
  Util.each(this.getStandardInclusions(), groupMember => {
    toReturn += Number(groupMember.getTotalPriceWithTax(this.getSelectedPriceLevel()))
  })
  return toReturn
}

ConfiguratorModel.prototype.getConfigOptionsTotal = function () {
  let toReturn = 0
  Util.each(this.getSectionList(), section => {
    Util.each(section.getGroups(), group => {
      const selectedOption = group.getOptions().filter(option => option.isSelected())
      if (selectedOption.length) {
        toReturn += Number(selectedOption[0].getPriceWithTax(this.getSelectedPriceLevel()))
      }
    })
  })
  return toReturn
}

ConfiguratorModel.prototype.getFormValues = function () { return this.formValues }
ConfiguratorModel.prototype.setFormValues = function (values) {
  this.formValues = values
}

ConfiguratorModel.prototype.submitToBackend = async function (abortController, dispatch) {
  dispatch({
    type: ACTIONS.SUBMIT_CONFIGURATION,
    payload: {
      submission: {
        loading: true,
        processed: false,
        error: ""
      }
    }
  })

  const payload = {
    method: "submitConfiguration",
    data: {
      user: this.getFormValues(),
      selectedPriceLevel: this.getSelectedPriceLevel(),
      design: {
        id: this.getSelectedDesign(),
        item: this.getDesign(this.getSelectedDesign()).getItem().getInternalID(),
        description: this.getDesign(this.getSelectedDesign()).getName(),
        quantity: 1,
        rate: Number(this.getDesign(this.getSelectedDesign()).getItem().getPrice(this.getSelectedPriceLevel())),
        amount: Util.round(this.getDesign(this.getSelectedDesign()).getItem().getPrice(this.getSelectedPriceLevel())),
        taxSchedule: this.getDesign(this.getSelectedDesign()).getItem().getTaxSchedule(),
        gross: Util.round(this.getDesign(this.getSelectedDesign()).getItem().getPriceWithTax(this.getSelectedPriceLevel()))
      },
      roof: {
        id: this.getSelectedRoof(),
        item: this.getSelectedRoof(),
        description: this.getRoof(this.getSelectedRoof()).getName(),
        quantity: 1,
        rate: Number(this.getRoof(this.getSelectedRoof()).getPrice(this.getSelectedPriceLevel())),
        amount: Util.round(this.getRoof(this.getSelectedRoof()).getPrice(this.getSelectedPriceLevel())),
        taxSchedule: this.getRoof(this.getSelectedRoof()).getTaxSchedule(),
        gross: Util.round(this.getRoof(this.getSelectedRoof()).getPriceWithTax(this.getSelectedPriceLevel()))
      },
      facade: {
        id: this.getSelectedFacade(),
        item: this.getDesign(this.getSelectedDesign()).getFacade(this.getSelectedFacade()).getItem().getInternalID(),
        description: this.getDesign(this.getSelectedDesign()).getFacade(this.getSelectedFacade()).getName(),
        quantity: 1,
        rate: Number(this.getDesign(this.getSelectedDesign()).getFacade(this.getSelectedFacade()).getItem().getPrice(this.getSelectedPriceLevel())),
        amount: Util.round(this.getDesign(this.getSelectedDesign()).getFacade(this.getSelectedFacade()).getItem().getPrice(this.getSelectedPriceLevel())),
        taxSchedule: this.getDesign(this.getSelectedDesign()).getFacade(this.getSelectedFacade()).getItem().getTaxSchedule(),
        gross: Util.round(this.getDesign(this.getSelectedDesign()).getFacade(this.getSelectedFacade()).getItem().getPriceWithTax(this.getSelectedPriceLevel()))
      },
      sections: [],
      standardInclusions: [],
      standardSiteCosts: []
    }
  }

  const sections = this.getSectionList()

  Util.each(sections, section => {
    const sectionObject = {
      name: section.getName(),
      groups: []
    }

    Util.each(section.getGroups(), group => {
      const groupObject = {
        name: group.getName(),
        options: []
      }

      Util.each(group.getOptions(), option => {
        if (option.isDefaultOption() || option.isSelected()) {
          const optionOject = {
            id: option.getItemInternalID(),
            item: option.getItemInternalID(),
            description: option.getItemName(),
            quantity: option.isSelected() ? 1 : -1,
            rate: Number(option.getPrice(this.getSelectedPriceLevel())),
            amount: Util.round(Number(option.getPrice(this.getSelectedPriceLevel())) === 0 ? 0 : Number(option.getPrice(this.getSelectedPriceLevel())) * (option.isSelected() ? 1 : -1)),
            taxSchedule: option.getTaxSchedule(),
            gross: Util.round(Number(option.getPriceWithTax(this.getSelectedPriceLevel())) === 0 ? 0 : Number(option.getPriceWithTax(this.getSelectedPriceLevel())) * (option.isSelected() ? 1 : -1))
          }
          groupObject.options.push(optionOject)
        }
      })

      sectionObject.groups.push(groupObject)
    })

    payload.data.sections.push(sectionObject)
  })

  const standardInclusions = this.getStandardInclusions()

  Util.each(standardInclusions, memberItem => {
    payload.data.standardInclusions.push({
      id: memberItem.getInternalID(),
      item: memberItem.getInternalID(),
      description: memberItem.getName(),
      quantity: memberItem.getQuantity(),
      rate: Number(memberItem.getPrice(this.getSelectedPriceLevel())),
      amount: Util.round(memberItem.getTotalPrice(this.getSelectedPriceLevel())),
      taxSchedule: memberItem.getTaxSchedule(),
      gross: Util.round(memberItem.getTotalPriceWithTax(this.getSelectedPriceLevel()))
    })
  })

  const standardSiteCosts = this.getStandardSiteCosts()

  Util.each(standardSiteCosts, memberItem => {
    payload.data.standardSiteCosts.push({
      id: memberItem.getInternalID(),
      item: memberItem.getInternalID(),
      description: memberItem.getName(),
      quantity: memberItem.getQuantity(),
      rate: Number(memberItem.getPrice(this.getSelectedPriceLevel())),
      amount: Util.round(memberItem.getTotalPrice(this.getSelectedPriceLevel())),
      taxSchedule: memberItem.getTaxSchedule(),
      gross: Util.round(memberItem.getTotalPriceWithTax(this.getSelectedPriceLevel()))
    })
  })

  try {
    const response = await fetch("/form_submissions/configurator", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ form_submission: payload }),
      signal: abortController.signal
    })

    if (!response.ok) { // non-200 HTTP code returned
      dispatch({
        type: ACTIONS.SUBMIT_CONFIGURATION,
        payload: {
          submission: {
            loading: false,
            processed: true,
            error: "Oops! Something went wrong, please reload the page to try again."
          }
        }
      })
      return
    }

    const data = await response.json()

    if (!data.success) {
      dispatch({
        type: ACTIONS.SUBMIT_CONFIGURATION,
        payload: {
          submission: {
            loading: false,
            processed: true,
            error: "An internal server error was encountered. Please try again later."
          }
        }
      })
      return
    }

    dispatch({
      type: ACTIONS.SUBMIT_CONFIGURATION,
      payload: {
        submission: {
          loading: false,
          processed: true,
          error: ""
        }
      }
    })
  } catch (e) {
    dispatch({
      type: ACTIONS.SUBMIT_CONFIGURATION,
      payload: {
        submission: {
          loading: false,
          processed: true,
          error: "Oops! It looks like you're having trouble connecting to the server. Please check your connection and try again."
        }
      }
    })
  }

  setTimeout(() => {
    window.isProcessing = false
  }, 10000)
}

export default ConfiguratorModel
